import { Locale } from "@ts-types/locale";
import { Channel } from "@constants/enums";

export enum ResponseStatus {
  Success = "SUCCESS",
  Error = "ERROR"
}

export interface ActionResponse<T = any> {
  data?: T;
  status: ResponseStatus;
  error?: {
    message: string;
  };
}

export interface MailActionProps<T> {
  data: T;
  locale: Locale;
  channel: Channel;
  options?: {
    fromEmail?: string;
    toEmail?: string;
    ccEmail?: string;
    bbcEmail?: string;
    replyToEmail?: string;
  };
}
