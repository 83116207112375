import { useContext, useEffect, useReducer, useState } from "react";
import { CartItemManager, CartItemManagerImpl } from "@utils/Cart/CartItemManager";
import { Cart, CartImpl } from "@utils/Cart/Cart";
import { UserContext } from "@components/Auth/UserProvider";
import { CurrencyCode, LocaleCode } from "@utils/Country/countryEnums";
import { Order, OrderImpl, OrderType } from "@utils/Order/Order";
import { OrderItemRepositoryImpl } from "@utils/Order/OrderItemRepository";
import { OrderRepositoryImpl } from "@utils/Order/OrderRepository";
import { getClientLocale } from "@utils/Localization/getClientLocale";
import { DiscountCodeImpl } from "@utils/Discount/DiscountCode";

export const useCartContext = ({
  initialPriceLevel,
  initialCurrency
}: {
  initialPriceLevel?: number;
  initialCurrency?: CurrencyCode;
}) => {
  const { user } = useContext(UserContext);
  const locale = getClientLocale();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const [cartItemManager, setCartItemManager] = useState<CartItemManager | null>(null);
  const [cart, setCart] = useState<Cart | null>(null);
  const [order, setOrder] = useState<Order | null>(null);

  const onDataChange = () => {
    forceUpdate();
  };

  useEffect(() => {
    const newCartItemManager = new CartItemManagerImpl(onDataChange);
    const newCart = new CartImpl(
      newCartItemManager,
      {
        userPriceLevel: user?.priceLevel || initialPriceLevel || 0,
        currency: initialCurrency || CurrencyCode.NOK
      },
      onDataChange
    );
    const newOrder = new OrderImpl({
      locale: locale as LocaleCode,
      cart: newCart,
      onChange: onDataChange,
      orderRepository: new OrderRepositoryImpl(),
      orderItemRepository: new OrderItemRepositoryImpl(),
      user: user
    });
    newCart.order = newOrder;

    setCartItemManager(newCartItemManager);
    setCart(newCart);
    setOrder(newOrder);

    if (order && !user?.customerProfile?.showRetailers) {
      order.retailer = undefined;
    }
  }, []);

  useEffect(() => {
    if (user) {
      cart?.setUserPriceLevel(user.priceLevel);
      if (order) {
        order.user = user;
      }
    }

    cart?.restoreState();
    order?.restoreState();

    if (cart && user && user.discount > 0 && !cart.discountCode) {
      const newCode = new DiscountCodeImpl();
      newCode.updateFromUser(user);
      cart.applyDiscountCode(newCode);
    }

    onDataChange();
  }, [cart, order, user]);

  useEffect(() => {
    if (order && user && cart?.discountCode) {
      order.type = OrderType.order;
    } else if (order) {
      order.type = OrderType.enquiry;
    }
  }, [cart?.discountCode]);

  return { cartItemManager, cart, order, onDataChange };
};
